import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useTheme } from 'ThemeContext';

import ICON_LOADER from 'assets/Images/loader.svg';

import { Wrapper, Content, Icon, Text } from './LoadingScreen.style';

const LoadingScreen = () => {
  const intl = useIntl();
  const theme = useTheme();
  return (
    <>
      <Wrapper theme={theme.loadingScreen}>
        <Content>
          <Icon
            src={ICON_LOADER}
            alt={intl.formatMessage({
              id: 'LOADING_SCREEN.PLEASE_WAIT'
            })}
          />
          <Text>
            <FormattedMessage id='LOADING_SCREEN.PLEASE_WAIT' />
            ...
          </Text>
        </Content>
      </Wrapper>
    </>
  );
};

export default LoadingScreen;
