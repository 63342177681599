import React, { useContext } from 'react';
import Select from 'react-select';
import { useIntl } from 'react-intl';
import { IntlContext } from 'IntlContext';
import { useTheme } from 'ThemeContext';

import {
  SelectBox,
  Control as CustomControl,
  SelectContainer as CustomSelectContainer,
  Placeholder as CustomPlaceholder,
  ValueContainer as CustomValueContainer,
  SingleValue as CustomSingleValue,
  Menu as CustomMenu,
  MenuList as CustomMenuList,
  Option as CustomOption,
  IndicatorSeparator as CustomIndicatorSeparator,
  IndicatorsContainer as CustomIndicatorsContainer,
  NoOptionsMessage as CustomNoOptionsMessage,
  DropdownIndicator as CustomDropdownIndicator,
  DropdownIndicatorIcon,
  SelectIcon
} from './SwitchLanguage.style';

const SelectContainer = props => {
  const theme = useTheme();
  return (
    <CustomSelectContainer
      theme={theme.select.container}
      ref={props.innerRef}
      {...props.innerProps}
      data-icon={props.selectProps.icon}
      data-label={props.selectProps.label ? props.selectProps.label : undefined}
      data-focused={props.selectProps.menuIsOpen ? true : undefined}
      data-filled={props.hasValue ? true : undefined}
      data-small={props.selectProps.small ? true : undefined}
    >
      {props.children}
    </CustomSelectContainer>
  );
};

const Control = props => (
  <CustomControl
    ref={props.innerRef}
    {...props.innerProps}
    data-selected={props.hasValue ? true : undefined}
  >
    {props.children}
  </CustomControl>
);

const Placeholder = props => {
  const theme = useTheme();

  return (
    <CustomPlaceholder
      theme={theme.select.placeholder}
      ref={props.innerRef}
      {...props.innerProps}
    >
      {props.selectProps.icon && (
        <SelectIcon className='material-icons'>
          {props.selectProps.icon}
        </SelectIcon>
      )}
      {props.children}
    </CustomPlaceholder>
  );
};

const SingleValue = props => {
  const theme = useTheme();
  return (
    <CustomSingleValue
      theme={theme.select.label}
      ref={props.innerRef}
      {...props.innerProps}
    >
      {props.selectProps.icon && (
        <SelectIcon className='material-icons'>
          {props.selectProps.icon}
        </SelectIcon>
      )}
      {props.children}
    </CustomSingleValue>
  );
};

const NoOptionsMessage = props => {
  const theme = useTheme();
  return (
    <CustomNoOptionsMessage
      theme={theme.select.noOption}
      ref={props.innerRef}
      {...props.innerProps}
    >
      {props.children}
    </CustomNoOptionsMessage>
  );
};

const ValueContainer = props => (
  <CustomValueContainer ref={props.innerRef} {...props.innerProps}>
    {props.children}
  </CustomValueContainer>
);

const Menu = props => {
  return (
    <CustomMenu ref={props.innerRef} {...props.innerProps}>
      {props.children}
    </CustomMenu>
  );
};

const MenuList = props => {
  const theme = useTheme();
  return (
    <CustomMenuList
      theme={theme.select.menu}
      ref={props.innerRef}
      {...props.innerProps}
    >
      {props.children}
    </CustomMenuList>
  );
};

const Option = props => {
  const theme = useTheme();

  return (
    <CustomOption
      theme={theme.select.option}
      ref={props.innerRef}
      data-selected={props.isSelected ? true : undefined}
      {...props.innerProps}
    >
      {props.children}
    </CustomOption>
  );
};

const IndicatorSeparator = props => (
  <CustomIndicatorSeparator ref={props.innerRef} {...props.innerProps} />
);

const IndicatorsContainer = props => (
  <CustomIndicatorsContainer ref={props.innerRef} {...props.innerProps}>
    {props.children}
  </CustomIndicatorsContainer>
);

const DropdownIndicator = props => {
  const theme = useTheme();
  return (
    <CustomDropdownIndicator ref={props.innerRef} {...props.innerProps}>
      <DropdownIndicatorIcon
        theme={theme.select.indicator}
        className='material-icons'
        data-focused={props.selectProps.menuIsOpen ? true : undefined}
      >
        arrow_drop_down
      </DropdownIndicatorIcon>
    </CustomDropdownIndicator>
  );
};

const SwitchLanguage = () => {
  const intl = useIntl();
  const { switchLanguage } = useContext(IntlContext);

  const languages = [
    {
      value: 'en-us',
      label: intl.formatMessage({ id: 'SWITCH_LANGUAGE.LANG_ENGLISH' })
    },
    {
      value: 'pl',
      label: intl.formatMessage({ id: 'SWITCH_LANGUAGE.LANG_POLISH' })
    },
    {
      value: 'de-de',
      label: intl.formatMessage({ id: 'SWITCH_LANGUAGE.LANG_GERMANY' })
    }
  ];

  return (
    <SelectBox>
      <Select
        placeholder={intl.formatMessage({ id: 'SWITCH_LANGUAGE.PLACEHOLDER' })}
        noOptionsMessage={() =>
          intl.formatMessage({ id: 'SWITCH_LANGUAGE.NO_OPTIONS' })
        }
        isSearchable={false}
        value={languages.filter(
          language =>
            language.value.toString().split('-')[0] === intl.locale.toString()
        )}
        options={languages}
        onChange={selectedOption => {
          switchLanguage(selectedOption.value);
        }}
        label={intl.formatMessage({
          id: `SWITCH_LANGUAGE.LANGUAGE`
        })}
        icon={'translate'}
        small={true}
        components={{
          SelectContainer,
          Control,
          Placeholder,
          ValueContainer,
          SingleValue,
          Menu,
          MenuList,
          Option,
          IndicatorSeparator,
          IndicatorsContainer,
          NoOptionsMessage,
          DropdownIndicator
        }}
      />
    </SelectBox>
  );
};

export default SwitchLanguage;
