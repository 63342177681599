import { css } from 'styled-components';
import { Media } from '../Mixins.style';

export const Panel = css`
  display: inline-block;
  max-width: 490px;
  width: 100%;
  padding: 35px 15px;
  box-sizing: border-box;

  ${Media.xxlg`
    max-width: 420px;
    padding: 25px 20px;
  `}

  ${Media.md`
    max-width: 380px;
    padding: 15px 20px;
  `}
`;

export const Title = css`
  display: block;
  width: 100%;
  margin-bottom: 82px;
  color: ${props => props.theme.color};
  font-family: 'Leelawadee UI', 'Raleway', sans-serif;
  font-size: 52px;
  line-height: 1.2;
  font-weight: 700;
  text-align: left;
  user-select: none;
  cursor: default;

  ${Media.xxlg`
    margin-bottom: 60px;
    font-size: 36px;
  `}

  ${Media.md`
    text-align: center;
  `}
`;

export const TitleDeco = css`
  position: relative;
  display: inline-block;

  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 0;
    border-bottom: 4px dotted ${props => props.theme.underlineColor};
    content: '';
  }
`;

export const Formbox = css`
  display: block;
  width: 100%;
`;

export const Contentbox = css`
  display: block;
  width: 100%;
  margin-bottom: 25px;
`;

export const Fieldbox = css`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 25px;
  width: 100%;
  margin-bottom: 10px;

  ${Media.xxlg`
    grid-gap: 15px;
    margin-bottom: 10px;
  `}
`;

export const Linkbox = css`
  display: block;
  width: 100%;
  margin-bottom: 40px;

  ${Media.xxlg`
    margin-bottom: 20px;
  `}
`;

export const Buttonbox = css`
  display: block;
  width: 100%;
  text-align: center;
  margin-top: 40px;

  ${Media.xxlg`
    margin-top: 20px;
  `}
`;

export const Text = css`
  display: inline;
  color: ${props => props.theme.color};
  font-size: 22px;
  line-height: 1.2;
  font-weight: 400;
  text-shadow: 0px 3px 6px ${props => props.theme.shadow};
  user-select: none;

  ${Media.xxlg`
    font-size: 18px;
  `}
`;
