import styled from 'styled-components';

import {
  Section as commonSection,
  Wrapper as commonWrapper,
  Content as commonContent,
  Navigation as commonNavigation,
  Imagebox as commonImagebox,
  Image as commonImage
} from 'assets/Common/SignWrapper.style';

export const Section = styled.div`
  ${commonSection}
`;

export const Wrapper = styled.div`
  ${commonWrapper}
`;

export const Content = styled.div`
  ${commonContent}
`;

export const Navigation = styled.div`
  ${commonNavigation}
`;

export const Imagebox = styled.div`
  ${commonImagebox}
`;

export const Image = styled.img`
  ${commonImage}
`;
