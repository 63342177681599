import { css } from 'styled-components';
import { Media } from '../Mixins.style';

export const Icon = css`
  display: inline-block;
  position: absolute;
  top: 50%;
  left: -50px;
  height: 36px;
  transition: 0.2s all;
  transform: translateY(-50%);
  color: ${props => props.theme.color};
  font-size: 36px;
  vertical-align: middle;
  user-select: none;
  cursor: pointer;

  ${Media.sm`
    left: -55px;
    height: 28px;
    font-size: 28px;
  `}
`;
