import React, { useContext } from 'react';
import { FORM_ERROR } from 'final-form';
import { useIntl } from 'react-intl';

import { signInUser } from 'requestAPI';
import { AuthContext } from 'AuthContext';

import Helmet from 'components/Helmet';
import Logo from 'components/Logo';
import SignInPanel from 'components/SignInPanel';

import { Content, Image, Imagebox, Section, Wrapper } from './SignInPage.style';

import IMG_BMS_ICON from 'assets/Images/SIFD_Orange_icon_BMS.svg';

const SignInPage = () => {
  const intl = useIntl();
  const [, userDispatch] = useContext(AuthContext);

  const handleSubmit = async values => {
    return await signInUser({
      credential: values
    })
      .then(res => {
        if (!res.error) userDispatch({ type: 'SIGN_IN', payload: res });
        else throw res;
      })
      .catch(err => {
        return {
          [FORM_ERROR]: intl.formatMessage({
            id: err.message || 'ERROR.LOGIN'
          })
        };
      });
  };

  return (
    <>
      <Helmet title={'LANG_PAGE_TITLE.SIGN_IN'} />
      <Section>
        <Wrapper>
          <Content>
            <Logo />
            <SignInPanel onSubmit={handleSubmit} />
          </Content>
        </Wrapper>
        <Wrapper hideMedium>
          <Imagebox>
            <Image src={IMG_BMS_ICON} alt='' />
          </Imagebox>
        </Wrapper>
      </Section>
    </>
  );
};

export default SignInPage;
