/*
/   STYLE-COMPONENT: BUTTON
*/

import { css } from 'styled-components';
import { Media } from '../Mixins.style';

export const Button = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 200px;
  width: auto;
  height: 52px;
  padding: 0 19px;
  border: 0;
  outline: none;
  transition: 0.2s all;
  background: ${props => props.theme.primaryBackground};
  background-image: ${props =>
    `linear-gradient(90deg, ${props.theme.primaryBackground}, ${props.theme.secondaryBackground})`};
  color: ${props => props.theme.color};
  border-radius: 70px;
  font-family: 'Quicksand', sans-serif;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  user-select: none;
  cursor: pointer;

  ${Media.xxlg`
    min-width: 180px;
    height: 49px;
    padding: 0 15px;
    font-size: 16px;
  `}

  &:hover {
    background: ${props => props.theme.secondaryBackground};
    background-image: ${props =>
      `linear-gradient(150deg, ${props.theme.primaryBackground}, ${props.theme.secondaryBackground})`};
  }

  &[disabled] {
    background: ${props => props.theme.disabledBackground};
    cursor: default;
  }
`;
