import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

import {
  Panel as commonPanel,
  Title as commonTitle,
  TitleDeco as commonTitleDeco,
  Formbox as commonFormbox,
  Fieldbox as commonFieldbox,
  Linkbox as commonLinkbox,
  Buttonbox as commonButtonbox
} from 'assets/Common/SignPanel.style';
import {
  InputHelperBox as commonInputHelperBox,
  InputBox as commonInputBox,
  Input as commonInput,
  InputIcon as commonInputIcon,
  InputSign as commonInputSign
} from 'assets/Common/Inputbox.style';
import { Button as commonButton } from 'assets/Common/Button.style';
import { Link as commonLink } from 'assets/Common/Link.style';
import {
  Errorbox as commonErrorbox,
  Error as commonError,
  ErrorIcon as commonErrorIcon
} from 'assets/Common/Error.style';

export const Panel = styled.div`
  ${commonPanel}
`;

export const Title = styled.h1`
  ${commonTitle}
`;

export const TitleDeco = styled.span`
  ${commonTitleDeco}
`;

export const Errorbox = styled.div`
  ${commonErrorbox}
`;

export const Error = styled.div`
  ${commonError}
`;

export const ErrorIcon = styled.i`
  ${commonErrorIcon}
`;

export const Formbox = styled.div`
  ${commonFormbox}
`;

export const Fieldbox = styled.div`
  ${commonFieldbox}
`;

export const InputHelperBox = styled.div`
  ${commonInputHelperBox}
`;

export const InputBox = styled.div`
  ${commonInputBox}
`;

export const Input = styled.input`
  ${commonInput}
`;

export const InputIcon = styled.i`
  ${commonInputIcon}
`;

export const InputSign = styled.i`
  ${commonInputSign}
`;

export const Linkbox = styled.div`
  ${commonLinkbox}
`;

export const Link = styled(RouterLink)`
  ${commonLink}
`;

export const Buttonbox = styled.div`
  ${commonButtonbox}
`;

export const Button = styled.button`
  ${commonButton}
`;
