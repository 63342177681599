import React from 'react';
import { useTheme } from 'ThemeContext';
import { FormattedMessage } from 'react-intl';
import Modules from 'components/Modules';

import { Panel, Title, TitleDeco, Contentbox } from './SelectModulePanel.style';

const SelectModulePanel = () => {
  const theme = useTheme();
  return (
    <Panel>
      <Title theme={theme.title}>
        <FormattedMessage id='SELECT_MODULE_PANEL.TITLE' />{' '}
        <TitleDeco theme={theme.titledeco}>
          <FormattedMessage id='SELECT_MODULE_PANEL.MODULE' />
        </TitleDeco>
      </Title>
      <Contentbox>
        <Modules />
      </Contentbox>
    </Panel>
  );
};

export default SelectModulePanel;
