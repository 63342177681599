import { css } from 'styled-components';
import { Media } from '../Mixins.style';
import { Colors } from '../Variables.style';

export const TooltipBox = css`
  position: absolute;
  top: 50%;
  right: -65px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65px;
  height: 65px;
  transform: translateY(-50%);
  z-index: 10;
  user-select: none;

  ${Media.xxlg`
    right: -55px;
    width: 55px;
    height: 55px;
  `}
`;

export const TooltipWrapper = css`
  position: relative;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100%;
  height: 100%;
`;

export const Tooltip = css`
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 100%;
  min-width: 300px;
  max-width: 300px;
  padding: 10px;
  border-radius: 15px;
  border: 2px dotted ${Colors.tanHide};
  background: ${Colors.wildSand};
  transform: translateY(-50%);
  font-size: 14px;
  line-height: 1.2;
  text-align: center;
  user-select: none;
  cursor: default;

  ${Media.xxlg`
    font-size: 12px;
  `}

  ${Media.md`
    left: inherit;
    right: 100%;
    min-width: 240px;
    max-width: 240px;
  `}
`;
