import React, { useEffect, useContext } from 'react';
import { AuthContext } from 'AuthContext';
import { signOutUser } from 'requestAPI';

import Helmet from 'components/Helmet';
import LoadingScreen from 'components/LoadingScreen';

const SignOutPage = () => {
  const [currentUser, userDispatch] = useContext(AuthContext);

  useEffect(() => {
    if (currentUser && currentUser.access_token && currentUser.isValidated) {
      (async () => {
        await signOutUser({
          access_token: currentUser.access_token
        });
        userDispatch({ type: 'LOGOUT' });
      })();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser.isValidated]);

  return (
    <>
      <Helmet title={'LANG_PAGE_TITLE.LOADING'} />
      <LoadingScreen />
    </>
  );
};

export default SignOutPage;
