import RootPage from 'views/RootPage';

const Routes = [
  {
    path: '*',
    name: 'BLACK-HOLE-PAGE',
    component: RootPage,
    exact: false
  }
];

export default Routes;
