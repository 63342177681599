import React, { useState } from 'react';
import { useTheme } from 'ThemeContext';
import { FormattedMessage } from 'react-intl';

import {
  TooltipBox,
  Icon,
  Tooltip,
  TooltipWrapper
} from './InputTooltip.style';

const InputTooltip = () => {
  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();
  return (
    <TooltipBox isOpen={isOpen}>
      <TooltipWrapper>
        <Icon
          theme={theme.tooltipIcon}
          className='material-icons'
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          help_outline
        </Icon>
        {isOpen && (
          <Tooltip>
            <FormattedMessage id='SIGN_UP_PANEL.PASSWORD_TOOLTIP' />
          </Tooltip>
        )}
      </TooltipWrapper>
    </TooltipBox>
  );
};

export default InputTooltip;
