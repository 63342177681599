import SelectModulePage from 'views/SelectModulePage';
import SignOutPage from 'views/SignOutPage';

const Routes = [
  {
    path: '/sign-out',
    name: 'SIGN-OUT-PAGE',
    component: SignOutPage,
    exact: true
  },
  {
    path: '/select-module',
    name: 'SELECT-MODULE',
    component: SelectModulePage,
    exact: true
  }
  // {
  //   path: '/select-customer',
  //   name: 'SELECT-CUSTOMER',
  //   component: SelectCustomerPage,
  //   exact: true
  // }
];

export default Routes;
