import React, { useContext } from 'react';
import { AuthContext } from 'AuthContext';
import { useTheme } from 'ThemeContext';

import { Box, Item } from './Modules.style';

import config from 'config';

const Modules = () => {
  const theme = useTheme();
  const [currentUser] = useContext(AuthContext);

  return (
    <Box>
      {config.modules
        .filter(
          item =>
            !item.privileges ||
            (currentUser.privileges &&
              Object.values(currentUser.privileges.companyRoles).find(role =>
                Object.values(role.permissions)
                  .map(permission => permission.permissionID)
                  .some(permission => item.privileges.includes(permission))
              ))
        )
        .map((item, key) => (
          <Item
            key={key}
            theme={theme.module[item.theme]}
            onClick={() => {
              window.location.assign(
                `${item.value}${
                  item.isExternal ? `?token=${currentUser.access_token}` : ''
                }`
              );
            }}
          >
            {item.name}
          </Item>
        ))}
    </Box>
  );
};

export default Modules;
