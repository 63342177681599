import { css } from 'styled-components';
import { Media } from '../Mixins.style';

export const Errorbox = css`
  display: block;
  width: 100%;
  min-height: 26px;
  margin-top: -45px;
  margin-bottom: 14px;
`;

export const Error = css`
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  color: ${props => props.theme.color};
  font-size: 18px;
  line-height: 1.2;
  font-weight: 400;
  text-shadow: 0px 3px 6px ${props => props.theme.shadow};
  cursor: default;

  ${Media.xxlg`
    font-size: 15px;
  `}
`;

export const ErrorIcon = css`
  margin-right: 15px;

  ${Media.xxlg`
    margin-right: 10px;
  `}
`;
