import React from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router } from 'react-router-dom';
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';

import { IntlProviderWrapper } from 'IntlContext';
import { ThemeProviderWrapper } from 'ThemeContext';
import { AuthProviderWrapper } from 'AuthContext';

import * as serviceWorker from 'serviceWorker';

import System from 'System';

import { GlobalStyle } from 'assets/Global.style';

ReactDOM.render(
  <HelmetProvider>
    <AuthProviderWrapper>
      <IntlProviderWrapper>
        <ThemeProviderWrapper>
          <Router>
            <GlobalStyle />
            <System />
          </Router>
        </ThemeProviderWrapper>
      </IntlProviderWrapper>
    </AuthProviderWrapper>
  </HelmetProvider>,

  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
